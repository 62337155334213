import { SassForm } from "./components/SassForm";
import "./css/style.css";

function App() {
  return (
    <>
      <SassForm />
    </>
  );
}

export default App;

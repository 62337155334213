import { useState, useEffect } from "react";

const SassForm = () => {
  const [htmlInput, setHtmlInput] = useState('');
  const [sassOutput, setSassOutput] = useState('');

  const handleHtmlInputChange = (e) => {
    setHtmlInput(e.target.value);
  };

  useEffect(() => {
    generateSass();
  }, [htmlInput]); // htmlInputが変更されたらgenerateSassを実行

  const generateSass = () => {
    let output = "";

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlInput, 'text/html');
    const allElements = doc.querySelectorAll('*[class]');

    let classStructure = {};

    allElements.forEach(el => {
      el.classList.forEach(className => {
        let parts = className.split('__');
        let component = parts[0];
        let element = parts[1] ? parts[1].split('--')[0] : '';
        let modifier = parts[1] && parts[1].includes('--') ? parts[1].split('--')[1] : '';

        if (!classStructure[component]) {
          classStructure[component] = { elements: {}, modifiers: new Set() };
        }

        if (element) {
          if (!classStructure[component].elements[element]) {
            classStructure[component].elements[element] = { modifiers: new Set() };
          }
        }

        if (modifier) {
          if (element) {
            classStructure[component].elements[element].modifiers.add(modifier);
          } else {
            classStructure[component].modifiers.add(modifier);
          }
        }
      });
    });

    for (let component in classStructure) {
      output += `/* --------------------------------------------------------------------- \n.${component}\n-----------------------------------------------------------------------*/\n.${component} {\n`;
      classStructure[component].modifiers.forEach(modifier => {
        output += `  &--${modifier} {\n    // @extend .${component};\n  }\n`;
      });
      const elementNames = Object.keys(classStructure[component].elements);
      elementNames.forEach((element, index) => {
        output += `  &__${element} {\n`;
        classStructure[component].elements[element].modifiers.forEach(modifier => {
          output += `    &--${modifier} {\n      // @extend .${component}__${element};\n    }\n`;
        });
        output += `  }\n`;
        if (index < elementNames.length - 1) output += '\n';
      });
      output += `}\n\n`;
    }

    setSassOutput(output);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sassOutput)
      .then(() => {
        alert('コピーしました！');
      })
      .catch(err => {
        console.error('クリップボードにコピーできませんでした。', err);
      });
  };

  return (
    <>
      <div className="sass-form">
        <h1 className="sass-form__ttl">Sass × Dart × FLOCSS 自動変換</h1>

        <div className="sass-form__inner">
          <div className="sass-form__item">
            <h3 className="sass-form__item-ttl">HTMLを構造ペーストしてね</h3>
            <textarea
              value={htmlInput}
              onChange={handleHtmlInputChange}
              cols="30"
              rows="10"
              className="sass-form__txt-area--html"
              placeholder="ここにhtmlの構造を入れてね"
            ></textarea>
          </div>

          <div className="sass-form__item">
            <h3 className="sass-form__item-ttl">出力されたものをコピーしてね</h3>
            <textarea
              value={sassOutput}
              cols="30"
              rows="10"
              className="sass-form__txt-area--sass"
              readOnly
            ></textarea>
          </div>
        </div>

        <div className="sass-form__btn-wrap">
          <button className="sass-form__btn" onClick={copyToClipboard}>コピー</button>
        </div>
      </div>
    </>
  );
};

export { SassForm };
